import BusinessMatchingMeetingModel from "@/web/store/models/BusinessMatchingMeetingModel";
import Constants from "@/web/constants";

export const types = {
  LOAD_BM_MEETINGS: "loadBMMeetings",
  LOAD_BM_MEETINGS_SUCCESS: "loadBMMeetingsSuccess",
  LOAD_BM_MEETINGS_ERROR: "loadBMMeetingsError",
};

export const state = () => ({
  status: null,
  error: null,
});

export const mutations = {
  [types.LOAD_BM_MEETINGS](state) {
    state.status = Constants.STATUS_LOADING;
  },
  [types.LOAD_BM_MEETINGS_SUCCESS](state) {
    state.status = Constants.STATUS_LOADED;
  },
  [types.LOAD_BM_MEETINGS_ERROR](state, error) {
    state.status = Constants.STATUS_ERROR;
    state.error = error;
  },
};

export const actions = {
  async fetchMeetingsFromComponents({ commit, dispatch, getters }, componentIds) {
    if (!getters.isLoading) {
      commit(types.LOAD_BM_MEETINGS);
      return Promise.all(
        componentIds.map(componentId =>
          dispatch("fetchMeetingsFromComponent", {
            componentId: componentId,
          })
        )
      )
        .then(result => {
          commit(types.LOAD_BM_MEETINGS_SUCCESS);
        })
        .catch(err => {
          commit(types.LOAD_BM_MEETINGS_ERROR, err);
        });
    } else {
      return Promise.resolve();
    }
  },

  async fetchMeetingsFromComponent({ rootState, getters }, { componentId }) {
    const eventId = rootState.eventId;
    const localMeetingIds = getters["getMeetingsFromComponent"](componentId).map(meeting => meeting.id);
    return BusinessMatchingMeetingModel.api()
      .get(`events/${eventId}/components/${componentId}/business_matching/invitations`, {
        dataTransformer: ({ data, headers }) => [...data.ingoing, ...data.outgoing],
      })
      .then(result => {
        const remoteMeetings = [...result.response.data.ingoing, ...result.response.data.outgoing];
        const remoteMeetingIds = remoteMeetings.map(meeting => meeting.id);
        let idsToDelete = localMeetingIds.filter(meetingId => !remoteMeetingIds.includes(meetingId));
        if (idsToDelete.length) {
          BusinessMatchingMeetingModel.delete(meeting => idsToDelete.includes(meeting.id));
        }
      });
  },

  async acceptMeeting({ rootState }, { componentId, meetingId }) {
    const eventId = rootState.eventId;
    return BusinessMatchingMeetingModel.api().post(
      `events/${eventId}/components/${componentId}/business_matching/meeting/${meetingId}/accept`
    );
  },

  async rescheduleMeeting({ rootState }, { componentId, meetingId, data }) {
    const eventId = rootState.eventId;
    const body = {
      time_start: data.timeStart,
      time_end: data.timeEnd,
      message: data.message,
      tag_agenda_place_id: data.placeId,
      bm_session_id: data.sessionId,
      custom_place_name: data.customPlaceName,
    };

    return BusinessMatchingMeetingModel.api()
      .post(`events/${eventId}/components/${componentId}/business_matching/meeting/${meetingId}/reschedule`, body)
      .then(result => {
        BusinessMatchingMeetingModel.delete(meetingId);
        return result;
      });
  },

  async declineMeeting({ rootState }, { componentId, meetingId }) {
    const eventId = rootState.eventId;
    return BusinessMatchingMeetingModel.api().post(
      `events/${eventId}/components/${componentId}/business_matching/meeting/${meetingId}/decline`
    );
  },

  async deleteMeeting({ rootState }, { componentId, meetingId }) {
    const eventId = rootState.eventId;
    return BusinessMatchingMeetingModel.api().delete(`events/${eventId}/components/${componentId}/business_matching/meeting/${meetingId}`, {
      delete: meetingId,
    });
  },
};

export const getters = {
  isLoading: state => state.status === Constants.STATUS_LOADING,

  getMeetingById: state => meetingId => {
    return BusinessMatchingMeetingModel.query().whereId(meetingId).withAllRecursive().first();
  },

  getMeetingsFromComponent: state => componentId => {
    return BusinessMatchingMeetingModel.query().where("event_component_id", componentId).withAllRecursive().all();
  },

  getAcceptedMeetings: state => {
    return BusinessMatchingMeetingModel.query()
      .where("status", Constants.BUSINESS_MATCHING_INVITATION_STATUS_ACCEPTED)
      .orderBy(meeting => new Date(meeting.time_start).getTime(), "desc")
      .withAllRecursive()
      .all();
  },

  getPendingOutgoingMeetings: (state, getters, rootState, rootGetters) => {
    const currentUserUuid = rootGetters["currentUser/uuid"];
    if (currentUserUuid) {
      return BusinessMatchingMeetingModel.query()
        .where("status", Constants.BUSINESS_MATCHING_INVITATION_STATUS_PENDING)
        .where("from_user_id", currentUserUuid)
        .orderBy(meeting => new Date(meeting.time_start).getTime(), "desc")
        .withAllRecursive()
        .all();
    }
    return [];
  },

  getPendingIncomingMeeting: (state, getters, rootState, rootGetters) => {
    const currentUserUuid = rootGetters["currentUser/uuid"];
    if (currentUserUuid) {
      return BusinessMatchingMeetingModel.query()
        .where("status", Constants.BUSINESS_MATCHING_INVITATION_STATUS_PENDING)
        .where("to_user_id", currentUserUuid)
        .orderBy(meeting => new Date(meeting.time_start).getTime(), "desc")
        .withAllRecursive()
        .all();
    }
    return [];
  },

  acceptedMeetingsCount: state => {
    return BusinessMatchingMeetingModel.query().where("status", Constants.BUSINESS_MATCHING_INVITATION_STATUS_ACCEPTED).count();
  },

  pendingMeetingsCount: state => {
    return BusinessMatchingMeetingModel.query().where("status", Constants.BUSINESS_MATCHING_INVITATION_STATUS_PENDING).count();
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
