import { Model } from "@vuex-orm/core";
import QuizAnswerModel from "@/web/store/models/QuizAnswerModel";

export default class QuizQuestionModel extends Model {
  static entity = "quiz_questions";

  static fields() {
    return {
      id: this.attr(null),
      survey_quiz_id: this.number(-1),
      mode: this.number(-1),
      order: this.number(-1),
      question: this.string(""),
      quiz_answer_ids: this.attr([]),
      survey_quiz_answers: this.hasManyBy(QuizAnswerModel, "quiz_answer_ids"),
    };
  }
}
