import axios from "@/web/services/axios";
import BusinessMatchingMeetingModel from "@/web/store/models/BusinessMatchingMeetingModel";
import Constants from "@/web/constants";

const types = {
  SEND_INVITATION: "sendInvitation",
  SEND_INVITATION_SUCCESS: "sendInvitationSuccess",
  SEND_INVITATION_ERROR: "sendInvitationError",
};

export const state = () => ({
  status: null,
  error: null,
  selectedDay: null,
});

export const mutations = {
  selectDay(state, day) {
    state.selectedDay = day;
  },
  [types.SEND_INVITATION](state) {
    state.status = Constants.STATUS_LOADING;
  },
  [types.SEND_INVITATION_SUCCESS](state) {
    state.status = Constants.STATUS_LOADED;
  },
  [types.SEND_INVITATION_ERROR](state, error) {
    state.error = error;
    state.status = Constants.STATUS_ERROR;
  },
};

export const actions = {
  async fetchFreeRideSuggestions({ rootState, getters, commit }, { componentId, userUuid, timeStart, timeEnd }) {
    const eventId = rootState.eventId;
    const params = {
      to_user: userUuid,
      time_start: timeStart,
      time_end: timeEnd,
    };
    return axios.get(`events/${eventId}/components/${componentId}/business_matching/free_places`, { params }).then(result => {
      return mapFreeRideSuggestions(result.data);
    });
  },

  async fetchSemiRideSuggestions({ rootState, commit }, { componentId, userUuid }) {
    const eventId = rootState.eventId;
    const params = {
      to_user: userUuid,
    };
    return axios.get(`events/${eventId}/components/${componentId}/business_matching/suggested_availability`, { params }).then(result => {
      return mapSemiRideSuggestions(result.data);
    });
  },

  async createInvitation(
    { rootState, commit, getters },
    { componentId, userUuid, timeStart, timeEnd, sessionId, message, placeId, customPlaceName }
  ) {
    if (!getters["isSending"]) {
      commit(types.SEND_INVITATION);
      const eventId = rootState.eventId;
      const data = {
        time_start: timeStart,
        time_end: timeEnd,
        to_user_id: userUuid,
        message: message,
        tag_agenda_place_id: placeId,
        bm_session_id: sessionId,
        custom_place_name: customPlaceName,
      };
      return BusinessMatchingMeetingModel.api()
        .post(`events/${eventId}/components/${componentId}/business_matching/meeting`, data)
        .then(result => {
          commit(types.SEND_INVITATION_SUCCESS);
        })
        .catch(err => {
          commit(types.SEND_INVITATION_ERROR, err);
        });
    } else {
      return Promise.resolve();
    }
  },
};

export const getters = {
  currentlySelectedDay: state => state.selectedDay,

  isSending: state => state.status === Constants.STATUS_LOADING,

  sendError: state => state.error,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

function mapSemiRideSuggestions(suggestions) {
  const common = suggestions.common_part_list;
  const mismatched = suggestions.mismatched_list;
  if (common.length || mismatched.length) {
    const eventDays = [...common, ...mismatched].map(timeslot => timeslot.agenda_session_day).filter(onlyUnique);

    return eventDays.map(day => {
      return {
        day: day,
        common: common.filter(timeslot => timeslot.agenda_session_day.id === day.id),
        mismatched: mismatched.filter(timeslot => timeslot.agenda_session_day.id === day.id),
      };
    });
  } else {
    return [];
  }
}

function mapFreeRideSuggestions(suggestions) {
  const slots = suggestions.common_part_list;
  if (slots.length) {
    return slots[0];
  } else {
    return [];
  }
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
