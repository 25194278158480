import { Constants } from "@/web/constants";
import FriendInvitationModel from "@/web/store/models/FriendInvitationModel";
import UserModel from "@/web/store/models/UserModel";
import axios from "@/web/services/axios";

export const types = {
  LOAD_INVITATIONS: "loadInvitations",
  LOAD_INVITATIONS_SUCCESS: "loadInvitationsSuccess",
  LOAD_INVITATIONS_ERROR: "loadInvitationsError",
  INVITATION_REQUEST: "invitationRequest",
  INVITATION_REQUEST_SUCCESS: "invitationRequestSuccess",
  INVITATION_REQUEST_ERROR: "invitationRequestError",
};

export const state = {
  status: null,
  error: null,
  loading: false,
  invitationsLoading: false,
  invitationsError: null,
  searchResults: null,
};

export const mutations = {
  [types.LOAD_INVITATIONS](state) {
    state.invitationsLoading = Constants.STATUS_LOADING;
  },
  [types.LOAD_INVITATIONS_SUCCESS](state) {
    state.invitationsLoading = Constants.STATUS_LOADED;
  },
  [types.LOAD_INVITATIONS_ERROR](state, error) {
    state.invitationsLoading = Constants.STATUS_ERROR;
    state.invitationsError = error;
  },

  [types.INVITATION_REQUEST](state) {
    state.loading = Constants.STATUS_LOADING;
  },
  [types.INVITATION_REQUEST_SUCCESS](state) {
    state.loading = Constants.STATUS_LOADED;
  },
  [types.INVITATION_REQUEST_ERROR](state, error) {
    state.loading = Constants.STATUS_ERROR;
    state.error = error;
  },
};

export const actions = {
  async loadInvitations({ commit, getters }, { showNotification }) {
    commit(types.LOAD_INVITATIONS);
    const currentPendingInvitations = getters["getRequestedInvitations"].reduce((acc, inv) => [...acc, inv.user_identity_token], []);

    return await FriendInvitationModel.api()
      .get(`friends/pending`, {
        dataTransformer: ({ data, headers }) => mapInvitations(data),
        persistBy: "create",
        persistOptions: {
          insertOrUpdate: ["users", "attachments"],
        },
      })
      .then(result => {
        commit(types.LOAD_INVITATIONS_SUCCESS);
        if (showNotification) {
          const newPendingInvitations = result.response.data.requested_friends;
          let newInvitations = newPendingInvitations.filter(x => !currentPendingInvitations.includes(x.user_identity_token));
          if (newInvitations.length) {
            commit("notifications/setFriendNotification", newInvitations[0], { root: true });
          }
        }
      })
      .catch(err => {
        commit(types.LOAD_INVITATIONS_ERROR, err);
      });
  },

  async acceptInvitation({ commit, getters }, userId) {
    if (!getters["isRequesting"]) {
      commit(types.INVITATION_REQUEST);
      return await axios
        .patch(`friends/accept/${userId}`)
        .then(result => {
          FriendInvitationModel.delete(userId);
          UserModel.update({
            where: userId,
            data: {
              is_friend: true,
            },
          });
          commit(types.INVITATION_REQUEST_SUCCESS);
        })
        .catch(err => {
          commit(types.INVITATION_REQUEST_ERROR, err);
        });
    }
    return Promise.resolve();
  },

  async rejectInvitation({ commit, getters }, userId) {
    if (!getters["isRequesting"]) {
      commit(types.INVITATION_REQUEST);
      return await axios
        .patch(`friends/decline/${userId}`)
        .then(result => {
          FriendInvitationModel.delete(userId);
          commit(types.INVITATION_REQUEST_SUCCESS);
        })
        .catch(err => {
          commit(types.INVITATION_REQUEST, err);
        });
    }
    return Promise.resolve();
  },

  async addToFriends({ commit, getters }, userId) {
    if (!getters["isRequesting"]) {
      commit(types.INVITATION_REQUEST);
      return await FriendInvitationModel.api()
        .post(
          `friends`,
          { user_identity_token: userId },
          {
            dataTransformer: ({ data, headers }) => mapInvitations(data),
          }
        )
        .then(result => {
          commit(types.INVITATION_REQUEST_SUCCESS);
        })
        .catch(err => {
          commit(types.INVITATION_REQUEST_ERROR, err);
        });
    }
    return Promise.resolve();
  },

  async removeFromFriends({ commit, getters }, userId) {
    if (!getters["isRequesting"]) {
      commit(types.INVITATION_REQUEST);
      return await axios
        .patch(`friends/remove/${userId}`)
        .then(result => {
          UserModel.update({
            where: userId,
            data: {
              is_friend: false,
            },
          });
          commit(types.INVITATION_REQUEST_SUCCESS);
        })
        .catch(err => {
          commit(types.INVITATION_REQUEST, err);
        });
    }
    return Promise.resolve();
  },

  async clearFriends({ commit }) {
    await FriendInvitationModel.deleteAll();
    await UserModel.update({
      data: {
        is_friend: false,
      },
    });
  },
};

export const getters = {
  getPendingInvitations(state) {
    return FriendInvitationModel.query().withAllRecursive().where("type", Constants.FRIENDS_INVITATION_PENDING).all();
  },

  getRequestedInvitations(state) {
    return FriendInvitationModel.query().withAllRecursive().where("type", Constants.FRIENDS_INVITATION_REQUESTED).all();
  },

  hasRequestedInvitation: state => userId => {
    return FriendInvitationModel.query()
      .withAllRecursive()
      .where("type", Constants.FRIENDS_INVITATION_REQUESTED)
      .where("user_identity_token", userId)
      .first();
  },

  hasPendingInvitation: state => userId => {
    return FriendInvitationModel.query()
      .withAllRecursive()
      .where("type", Constants.FRIENDS_INVITATION_PENDING)
      .where("user_identity_token", userId)
      .first();
  },

  hasInvitationWithUser: state => userId => {
    return FriendInvitationModel.query().withAllRecursive().where("user_identity_token", userId).first();
  },

  isLoaded(state) {
    return state.status === Constants.STATUS_LOADED;
  },

  isRequesting(state) {
    return state.loading === Constants.STATUS_LOADING;
  },

  areInvitationsLoading(state) {
    return state.invitationsLoading === Constants.STATUS_LOADING;
  },
};

function mapInvitations(data) {
  const pending = data.pending_friends;
  const requested = data.requested_friends;
  const mappedPending = pending.reduce(
    (acc, inv) => [
      ...acc,
      {
        user_identity_token: inv.user_identity_token,
        user: inv,
        type: Constants.FRIENDS_INVITATION_PENDING,
      },
    ],
    []
  );
  const mappedRequested = requested.reduce(
    (acc, inv) => [
      ...acc,
      {
        user_identity_token: inv.user_identity_token,
        user: inv,
        type: Constants.FRIENDS_INVITATION_REQUESTED,
      },
    ],
    []
  );
  return [...mappedPending, ...mappedRequested];
}

export default {
  namespaced: true,
  state: state,
  mutations,
  actions,
  getters,
};
