<template>
  <div class="modal-card">
    <header class="modal-card-head has-text-centered">
      <h1 class="modal-card-title">
        {{ $t("white_list.white_list_access_denied_title") }}
      </h1>
      <div class="modal-close-button" @click="$parent.close()">
        <close-icon class="svg-icon close-icon"></close-icon>
      </div>
    </header>
    <section class="modal-card-body pt-1 modal-card-foot">
      <div class="size-6 subtitle has-text-centered">
        {{ $t("white_list.white_list_description") }}
      </div>

      <div v-if="!isSuccess && !error">
        <button class="button is-primary" style="margin-top: 2rem" @click="joinToEvent">
          {{ $t("white_list.white_list_send_request") }}
        </button>
      </div>

      <div v-if="isSuccess" class="mt-2 is-size-4 has-text-weight-bold has-text-success">
        <div class="has-text-centered">
          {{ $t("white_list.white_list_send_request_success") }}
        </div>
      </div>

      <div v-else-if="error" class="mt-2 is-size-4 has-text-weight-bold has-text-danger">
        <div class="has-text-centered">
          {{ error | pluckError }}
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RequestMixin from "@/shared/mixins/RequestMixin";
import CloseIcon from "@/assets/icon_close.svg";

export default {
  mixins: [RequestMixin],

  name: "WhitelistModal",

  components: { CloseIcon },

  data() {
    return {
      isSuccess: false,
    };
  },

  requests: {
    async joinToEvent() {
      await this.requestJoinToEvent(this.eventId);
      this.isSuccess = true;
    },
  },

  methods: {
    ...mapActions("auth", ["requestJoinToEvent"]),
  },

  computed: {
    ...mapState(["eventId"]),
  },
};
</script>
