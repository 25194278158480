import { Model } from "@vuex-orm/core";
import AttachmentModel from "./AttachmentModel";

export default class EventDisplayModel extends Model {
  static entity = "eventDisplay";

  static fields() {
    return {
      id: this.number(null),
      title: this.string(),
      place_address: this.string(),
      start_date: this.string(),
      end_date: this.string(),
      timezone: this.string(),
      logo_id: this.attr(null),
      logo: this.belongsTo(AttachmentModel, "logo_id"),
      app_id: this.number(),
      entrance_with_tickets: this.boolean(),
    };
  }
}
