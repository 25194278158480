import createStorage from "@/web/services/storage";
import { detectBrowserLanguage } from "@/shared/utils";
import axios from "@/web/services/axios";
import { languages, countries } from "countries-list";

const localeStorage = createStorage("locale");

// you have to add all locales also into shared/utils/dayjs-locales.js
const supportedLocalesUs = [
  { title: "EN", countryCode: "us", localeCode: "en" },
  { title: "PL", countryCode: "pl", localeCode: "pl" },
  //Czech title should be "CS" as language code instead of country code. Revert ?
  { title: "CZ", countryCode: "cz", localeCode: "cs" },
  { title: "DE", countryCode: "de", localeCode: "de" },
  { title: "RU", countryCode: "ru", localeCode: "ru" },
  { title: "HU", countryCode: "hu", localeCode: "hu" },
  { title: "RO", countryCode: "ro", localeCode: "ro" },
  { title: "FR", countryCode: "fr", localeCode: "fr" },
  { title: "BG", countryCode: "bg", localeCode: "bg" },
];

// you have to add all locales also into shared/utils/dayjs-locales.js
const supportedLocalesBritish = [
  { title: "EN", countryCode: "gb", localeCode: "en" },
  { title: "PL", countryCode: "pl", localeCode: "pl" },
  //Czech title should be "CS" as language code instead of country code. Revert ?
  { title: "CZ", countryCode: "cz", localeCode: "cs" },
  { title: "DE", countryCode: "de", localeCode: "de" },
  { title: "RU", countryCode: "ru", localeCode: "ru" },
  { title: "HU", countryCode: "hu", localeCode: "hu" },
  { title: "RO", countryCode: "ro", localeCode: "ro" },
  { title: "FR", countryCode: "fr", localeCode: "fr" },
  { title: "BG", countryCode: "bg", localeCode: "bg" },
];

// you have to add all locales also into shared/utils/dayjs-locales.js
const supportedLocalesGoogleRomania = [
  { title: "RO", countryCode: "ro", localeCode: "ro" },
  { title: "EN", countryCode: "gb", localeCode: "en" },
];

// you have to add all locales also into shared/utils/dayjs-locales.js
const supportedLocalesGooglePoland = [
  { title: "PL", countryCode: "pl", localeCode: "pl" },
  { title: "EN", countryCode: "gb", localeCode: "en" },
];

const supportedLocalesTargiSpecjal = [{ title: "PL", countryCode: "pl", localeCode: "pl" }];

const supportedLocalesPchet = [
  { title: "PL", countryCode: "pl", localeCode: "pl" },
  { title: "EN", countryCode: "gb", localeCode: "en" },
];

export const state = () => ({
  currentLocale: null,
  localeSelectionEnabled: false,
  isLoading: false,
  customChangeEventSlug: null,
  eventLocales: null,
});

export const mutations = {
  setCurrentLocale(state, locale) {
    state.currentLocale = locale;
    state.localeSelectionEnabled = true;
  },

  setConstantLocale(state, locale) {
    state.localeSelectionEnabled = false;
    state.currentLocale = locale;
  },

  setIsLocaleLoading(state, isLoading) {
    state.isLoading = isLoading;
  },

  setCustomEventSlug(state, eventSlug) {
    state.customChangeEventSlug = eventSlug;
  },

  setEventLocales(state, eventLocales) {
    state.eventLocales = eventLocales;
  },
};

export const actions = {
  initLocale({ getters, rootState, commit }, eventSlug) {
    commit("setCustomEventSlug", eventSlug);
    const currentLocale = localeStorage.retrieve(eventSlug);
    const detectedLocale = detectBrowserLanguage();
    const localeToInitialize = (currentLocale && typeof currentLocale === "object" && currentLocale.localeCode) || detectedLocale;
    const localeData = getters.supportedLocales.find(locale => locale.localeCode === localeToInitialize) || getters.supportedLocales[0];
    if (eventSlug.includes("romania")) {
      let customLocale = supportedLocalesGoogleRomania.find(locale => locale.localeCode === "ro");
      commit("setCurrentLocale", customLocale);
      return customLocale;
    } else if (eventSlug.includes("google-cloud-summit-poland")) {
      let customLocale = supportedLocalesGooglePoland.find(locale => locale.localeCode === "pl");
      commit("setCurrentLocale", customLocale);
      return customLocale;
    } else if (eventSlug.includes("targi-specjal")) {
      let customLocale = supportedLocalesTargiSpecjal[0];
      commit("setConstantLocale", customLocale);
      return customLocale;
    } else if (eventSlug.includes("supportedLocalesPchet")) {
      let customLocales = supportedLocalesPchet;
      const localeToSet = customLocales.includes(localeData) ? localeData : supportedLocalesPchet[0];
      commit("setCurrentLocale", localeToSet);
    } else {
      commit("setCurrentLocale", localeData);
      return localeData;
    }
  },

  async setCurrentLocale({ getters, rootState, commit, dispatch }, localeCode) {
    const eventSlug = rootState.slug;
    const localeData = getters.supportedLocales.find(locale => locale.localeCode === localeCode) || getters.supportedLocales[0];
    localeStorage.store(eventSlug, localeData);
    commit("setCurrentLocale", localeData);
    commit("setIsLocaleLoading", true);
    await dispatch("deepReloadEventData", eventSlug, { root: true });
    setTimeout(() => {
      commit("setIsLocaleLoading", false);
    }, 4000);
  },

  setConstantLocale({ getters, rootState, commit, dispatch }, localeCode) {
    const eventSlug = rootState.slug;
    const currentLocale = localeStorage.retrieve(eventSlug);
    const localeData = getters.supportedLocales.find(locale => locale.localeCode === localeCode) || getters.supportedLocales[0];
    commit("setConstantLocale", localeData);
    localeStorage.store(eventSlug, localeData);
    if (localeCode.toLowerCase() !== (currentLocale && currentLocale.localeCode.toLowerCase())) {
      dispatch("deepReloadEventData", eventSlug, { root: true });
    }
  },

  async fetchEventLocales({ getters, rootState, commit, dispatch }) {
    return axios.get(`events/${rootState.eventId}/languages`).then(result => {
      commit("setEventLocales", mapEventLocales(result.data));
    });
  },
};

export const getters = {
  currentLocale: state => state.currentLocale,

  localeSelectionEnabled: state => state.localeSelectionEnabled,

  supportedLocales: (state, getters, rootState) => {
    if (state.customChangeEventSlug && state.customChangeEventSlug.includes("ilpn-conference")) {
      return supportedLocalesBritish;
    } else if (state.customChangeEventSlug && state.customChangeEventSlug.includes("romania")) {
      return supportedLocalesGoogleRomania;
    } else if (state.customChangeEventSlug && state.customChangeEventSlug.includes("google-cloud-summit-poland")) {
      return supportedLocalesGooglePoland;
    } else if (state.customChangeEventSlug && state.customChangeEventSlug.includes("pchet")) {
      return supportedLocalesPchet;
    } else if (state.customChangeEventSlug && state.customChangeEventSlug.includes("targi-specjal")) {
      return supportedLocalesTargiSpecjal;
    } else {
      return supportedLocalesUs;
    }
  },

  isLocaleLoading(state) {
    return state.isLoading;
  },

  eventLocales(state) {
    return state.eventLocales;
  },
};

function mapEventLocales(eventLocales) {
  return eventLocales
    .map(locale => {
      let foundCountry =
        getFixedCountry(locale.lang_code) ||
        Object.entries(countries).find(([country, data]) => data.languages.find(lang => lang === locale.lang_code));
      return {
        countryCode: (foundCountry && foundCountry[0]) || locale.lang_code,
        localeCode: locale.lang_code,
        title: languages[locale.lang_code].native,
        isDefault: locale.default_lang,
      };
    })
    .sort((a, b) => b.isDefault - a.isDefault);
}

function getFixedCountry(localeCode) {
  switch (localeCode) {
    case "en":
      return ["us", null];
    case "fr":
      return ["fr", null];
    case "es":
      return ["es", null];
    default:
      return null;
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
