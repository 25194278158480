import { Constants } from "@/web/constants";
import ExhibitorUpdateModel from "@/web/store/models/ExhibitorUpdateModel";
import ExhibitorModel from "@/web/store/models/ExhibitorModel";
import ExhibitorTagModel from "@/web/store/models/ExhibitorTagModel";

export const state = () => ({
  exhibitorUpdatesStatus: null,
  exhibitorUpdatesError: null,
  navigateBackWithError: false,
  createUpdateStatus: null,
  createUpdateError: null,
});

export const types = {
  LOAD_EXHIBITOR_UPDATES: "loadExhibitorUpdates",
  LOAD_EXHIBITOR_UPDATES_SUCCESS: "loadExhibitorUpdatesSuccess",
  LOAD_EXHIBITOR_UPDATES_ERROR: "loadExhibitorUpdatesError",
  LOAD_EXHIBITOR_UPDATES_FAILED: "loadExhibitorUpdatesFailed",
  CREATE_EXHIBITOR_UPDATE: "createExhibitorUpdate",
  CREATE_EXHIBITOR_UPDATE_SUCCESS: "createExhibitorUpdateSuccess",
  CREATE_EXHIBITOR_UPDATE_ERROR: "createExhibitorUpdateError",
};

export const mutations = {
  [types.LOAD_EXHIBITOR_UPDATES](state) {
    state.exhibitorUpdatesStatus = Constants.STATUS_LOADING;
  },
  [types.LOAD_EXHIBITOR_UPDATES_SUCCESS](state) {
    state.exhibitorUpdatesStatus = Constants.STATUS_LOADED;
  },
  [types.LOAD_EXHIBITOR_UPDATES_ERROR](state, error) {
    state.exhibitorUpdatesStatus = Constants.STATUS_ERROR;
    state.exhibitorUpdatesError = error;
  },
  [types.LOAD_EXHIBITOR_UPDATES_FAILED](state) {
    state.exhibitorUpdatesStatus = Constants.STATUS_NO_DATA;
  },
  [types.CREATE_EXHIBITOR_UPDATE](state) {
    state.createUpdateStatus = Constants.STATUS_LOADING;
  },
  [types.CREATE_EXHIBITOR_UPDATE_SUCCESS](state) {
    state.createUpdateStatus = Constants.STATUS_LOADED;
  },
  [types.CREATE_EXHIBITOR_UPDATE_ERROR](state, error) {
    state.createUpdateStatus = Constants.STATUS_ERROR;
    state.createUpdateError = error;
  },
};
export const actions = {
  async fetchExhibitorUpdates({ commit, rootState }, { exhibitorId }) {
    commit(types.LOAD_EXHIBITOR_UPDATES);
    let exhibitor = ExhibitorModel.query().find(exhibitorId);
    if (exhibitor) {
      return ExhibitorUpdateModel.api()
        .get(`events/${rootState.eventId}/components/${exhibitor.event_component_id}/exhibitor/${exhibitorId}/updates`)
        .then(exhibitorUpdates => {
          commit(types.LOAD_EXHIBITOR_UPDATES_SUCCESS);
        })
        .catch(err => {
          commit(types.LOAD_EXHIBITOR_UPDATES_ERROR, err);
          throw err;
        });
    } else {
      commit(types.LOAD_EXHIBITOR_UPDATES_FAILED);
      return Promise.resolve();
    }
  },

  async removeExhibitorUpdate({ commit, rootState }, { componentId, exhibitorId, exhibitorUpdateId }) {
    try {
      await ExhibitorUpdateModel.api().delete(
        `events/${rootState.eventId}/components/${componentId}/exhibitor/${exhibitorId}/update/${exhibitorUpdateId}`
      );
    } catch (err) {
      console.error(err);
    }
  },

  async initCreator({ commit, rootState, dispatch }, { exhibitorId }) {
    let exhibitor = ExhibitorModel.query().find(exhibitorId);
    if (exhibitor) {
      try {
        await dispatch("locales/fetchEventLocales", null, { root: true });
        await ExhibitorTagModel.api().get(`events/${rootState.eventId}/tags/tag_exhibitors`);
        await ExhibitorModel.api().get(
          `events/${rootState.eventId}/components/${exhibitor.event_component_id}/exhibitor/${exhibitorId}/translations`
        );
      } catch (error) {
        commit(types.LOAD_EXHIBITOR_UPDATES_ERROR, error);
      }
    } else {
      commit(types.LOAD_EXHIBITOR_UPDATES_FAILED);
    }
  },

  async createExhibitorUpdate({ commit, rootState, dispatch }, { exhibitorId, exhibitorUpdate, extraNote }) {
    commit(types.CREATE_EXHIBITOR_UPDATE);
    let exhibitor = ExhibitorModel.query().find(exhibitorId);
    if (exhibitor) {
      let body = {
        updated_exhibitor: exhibitorUpdate,
        extra_note: extraNote,
      };
      return ExhibitorUpdateModel.api()
        .post(`events/${rootState.eventId}/components/${exhibitor.event_component_id}/exhibitor/${exhibitorId}/updates`, body)
        .then(result => {
          commit(types.CREATE_EXHIBITOR_UPDATE_SUCCESS);
        })
        .catch(err => {
          commit(types.CREATE_EXHIBITOR_UPDATE_ERROR, err);
        });
    }
  },
};
export const getters = {
  isLoading(state) {
    return state.exhibitorUpdatesStatus === Constants.STATUS_LOADING;
  },

  isLoaded(state) {
    return state.exhibitorUpdatesStatus === Constants.STATUS_LOADED;
  },

  forceNavigateBack(state) {
    return state.exhibitorUpdatesStatus === Constants.STATUS_NO_DATA;
  },

  isCreating(state) {
    return state.createUpdateStatus === Constants.STATUS_LOADING;
  },

  isCreated(state) {
    return state.createUpdateStatus === Constants.STATUS_LOADED;
  },

  getUpdatesForExhibitorId: state => exhibitorId => {
    return ExhibitorUpdateModel.query()
      .where("exhibitor_id", exhibitorId)
      .withAllRecursive()
      .all()
      .filter(it => it.status !== Constants.MODERATION_STATUS_CANCELED)
      .sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
  },

  getExhibitorUpdate: state => exhibitorUpdateId => {
    return ExhibitorUpdateModel.query().withAllRecursive().find(exhibitorUpdateId);
  },

  exhibitorTags(state) {
    return ExhibitorTagModel.query().all();
  },

  getExhibitorWithTranslations: state => exhibitorId => {
    return ExhibitorModel.query().withAllRecursive().find(exhibitorId);
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
