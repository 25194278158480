import { Model } from "@vuex-orm/core";
import EventDisplayModel from "./EventDisplayModel";
import TicketModel from "./TicketModel";
import AgendaSessionDisplayModel from "@/web/store/models/AgendaSessionDisplayModel";

export default class UserSessionTicketModel extends Model {
  static entity = "userAgendaSessionTickets";

  static fields() {
    return {
      id: this.number(null),
      ticket_reservation_uuid: this.string(null),
      agenda_session_display_data_id: this.attr(null),
      agenda_session_display_data: this.belongsTo(AgendaSessionDisplayModel, "agenda_session_display_data_id"),
      event_display_data_id: this.attr(null),
      event_display_data: this.belongsTo(EventDisplayModel, "event_display_data_id"),
      agenda_session_ticket_id: this.attr(null),
      agenda_session_ticket: this.belongsTo(TicketModel, "agenda_session_ticket_id"),
    };
  }
}
