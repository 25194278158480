import LpConfigConstants from "@/shared/constants/lp-config-constants";
import axios from "@/web/services/axios";
import { generateModulesFromComponentList } from "@/shared/utils";
import FormBuilderConstants from "@/web/views/dev/formbuilder/form-builder-constants";

export const state = () => ({
  livePreviewEnabled: false,
  header: {},
  timer: {},
  theme: {},
  inbox: {},
  side_menu: {},
  features: {},
  help: {},
  navbar: {},
  footer: {},
  payment: {},
  translations: {},
  modules: [],
  register_form: {},
  ticket_forms: {},
  language: null,
});

export const mutations = {
  setConfig(state, config) {
    const rootFields = LpConfigConstants.ROOT_FIELDS;
    state[rootFields.MODULES] = addTempIds(fixModulesOrder((config[rootFields.MODULES] && [...config[rootFields.MODULES]]) || []));
    state[rootFields.HEADER] = config[rootFields.HEADER] || {};
    state[rootFields.TIMER] = config[rootFields.TIMER] || {};
    state[rootFields.THEME] = config[rootFields.THEME] || {};
    state[rootFields.INBOX] = config[rootFields.INBOX] || {};
    state[rootFields.SIDE_MENU] = config[rootFields.SIDE_MENU] || {};
    state[rootFields.FEATURES] = config[rootFields.FEATURES] || {};
    state[rootFields.HELP] = config[rootFields.HELP] || {};
    state[rootFields.NAVBAR] = config[rootFields.NAVBAR] || {};
    state[rootFields.FOOTER] = config[rootFields.FOOTER] || {};
    state[rootFields.PAYMENT] = config[rootFields.PAYMENT] || {};
    state[rootFields.TRANSLATIONS] = config[rootFields.TRANSLATIONS] || {};
    state[rootFields.REGISTER_FORM] = config[rootFields.REGISTER_FORM] || {};
    state[rootFields.TICKET_FORMS] = config[rootFields.TICKET_FORMS] || {};
    state[rootFields.LANGUAGE] = config[rootFields.LANGUAGE] || null;
  },

  setLivePreview(state, livePreviewEnabled) {
    state.livePreviewEnabled = livePreviewEnabled;
  },

  setHeaderConfig(state, headerConfig) {
    state.header = headerConfig;
  },

  setTimerConfig(state, timerConfig) {
    state.timer = timerConfig;
  },

  setThemeConfig(state, themeConfig) {
    state.theme = themeConfig;
  },

  setInboxConfig(state, inboxConfig) {
    state.inbox = inboxConfig;
  },

  setSideMenuConfig(state, sideMenuConfig) {
    state.side_menu = sideMenuConfig;
  },

  setFeaturesConfig(state, featuresConfig) {
    state.features = featuresConfig;
  },

  setHelpConfig(state, helpConfig) {
    state.help = helpConfig;
  },

  setNavbarConfig(state, navbarConfig) {
    state.navbar = navbarConfig;
  },

  setFooterConfig(state, footerConfig) {
    state.footer = footerConfig;
  },

  setPaymentConfig(state, paymentConfig) {
    state.payment = paymentConfig;
  },

  setModulesConfig(state, modulesConfig) {
    state.modules = modulesConfig;
  },

  setRegisterFormConfig(state, registerFormConfig) {
    state.register_form = registerFormConfig;
  },

  setTicketFormsConfig(state, ticketFormsConfig) {
    state.ticket_forms = ticketFormsConfig;
  },

  updateTranslations(state, newTranslations) {
    state.translations = { ...deepMergeTranslationObjects(state.translations, newTranslations) };
  },

  removeTranslationKey(state, translationKey) {
    Object.values(state.translations).forEach(translations => delete translations[translationKey]);
    state.translations = { ...state.translations };
  },

  //MODULES
  changeModuleOrder(state, { currentOrder, newOrder }) {
    state.modules = [...swapItems(state.modules, currentOrder, newOrder)];
  },

  removeModule(state, module) {
    state.modules.splice(module.order, 1);
    fixModulesOrder(state.modules);
  },

  upsertModule(state, module) {
    state.modules.splice(module.order, 1, module);
  },
};

export const actions = {
  async updateLpConfig({ commit, dispatch, getters, rootState }, additionalConfig) {
    const eventId = rootState["eventId"];
    let config = getters["currentConfig"];
    if (additionalConfig) {
      config = { ...config, ...additionalConfig };
    }
    const body = {
      lp_settings: JSON.stringify(config),
    };
    await axios.post(`events/${eventId}/update_event_lp`, body).then(result => {
      const lpSettingsString = result.data.lp_settings;
      if (lpSettingsString) {
        const lpSettings = JSON.parse(lpSettingsString);
        commit("setLpSettings", lpSettings, { root: true });
      }
    });
  },

  generateModulesFromComponents({ commit, getters, rootState, state, rootGetters }, components) {
    const event = rootState["event"];
    const tickets = rootGetters["eventTickets/getAllTickets"];
    const modules = addTempIds(fixModulesOrder(generateModulesFromComponentList(event, tickets, components)));
    commit("setModulesConfig", modules);
  },

  async updateCustomRegisterForm({ commit, dispatch, getters, rootState }, customRegisterForm) {
    const eventId = rootState["eventId"];
    let config = rootState["settings"];
    if (customRegisterForm) {
      config = { ...config, ...customRegisterForm };
    }
    const body = {
      lp_settings: JSON.stringify(config),
    };
    await axios.post(`events/${eventId}/update_event_lp`, body).then(result => {
      const lpSettingsString = result.data.lp_settings;
      if (lpSettingsString) {
        let lpSettings = JSON.parse(lpSettingsString);
        commit("setLpSettings", lpSettings, { root: true });
        commit("setRegisterFormConfig", lpSettings[LpConfigConstants.ROOT_FIELDS.REGISTER_FORM]);
      }
    });
    await dispatch("updateFieldIds");
  },

  async updateCustomTicketsForm({ commit, dispatch, getters, rootState }, { formData, eventTicketIds }) {
    const eventId = rootState["eventId"];
    let config = rootState["settings"];

    const ticketForms = { ...config[LpConfigConstants.ROOT_FIELDS.TICKET_FORMS] };
    eventTicketIds.forEach(id => {
      ticketForms[id] = formData;
    });
    config = { ...config, [LpConfigConstants.ROOT_FIELDS.TICKET_FORMS]: ticketForms };
    const body = {
      lp_settings: JSON.stringify(config),
    };
    await axios.post(`events/${eventId}/update_event_lp`, body).then(result => {
      const lpSettingsString = result.data.lp_settings;
      if (lpSettingsString) {
        let lpSettings = JSON.parse(lpSettingsString);
        commit("setLpSettings", lpSettings, { root: true });
        commit("setTicketFormsConfig", lpSettings[LpConfigConstants.ROOT_FIELDS.TICKET_FORMS]);
      }
    });
    await dispatch("updateFieldIds");
  },

  async updateFieldIds({ state, rootState, dispatch }) {
    const eventId = rootState.eventId;
    let currentFields = await dispatch("currentUser/fetchUserMeta", null, { root: true });
    //reserved specific field names are used for invoice/correspondence data
    let fieldNamesFromForms = new Set(FormBuilderConstants.RESERVED_SPECIFIC_FIELD_NAMES);
    if (state.register_form && state.register_form[FormBuilderConstants.FIELDS]) {
      state.register_form[FormBuilderConstants.FIELDS].forEach(field =>
        fieldNamesFromForms.add(field[FormBuilderConstants.FIELD_PROPERTIES.ID])
      );
    }
    Object.values(state.ticket_forms).forEach(form => {
      form[FormBuilderConstants.FIELDS].forEach(field => {
        fieldNamesFromForms.add(field[FormBuilderConstants.FIELD_PROPERTIES.ID]);
      });
    });

    let fieldIdsToRemove = currentFields.filter(field => !fieldNamesFromForms.has(field.name));
    let fieldNamesToAdd = [...fieldNamesFromForms].filter(name => !currentFields.find(field => field.name === name));

    let addPromises = fieldNamesToAdd.map(fieldId => axios.post(`events/${eventId}/specific/add_field`, { name: fieldId }));
    let removePromises = fieldIdsToRemove.map(field => axios.delete(`events/${eventId}/specific/destroy_field/${field.id}`));

    await Promise.all([...removePromises, ...addPromises]);
    await dispatch("currentUser/fetchUserMeta", null, { root: true });
  },
};

export const getters = {
  livePreviewEnabled(state) {
    return state.livePreviewEnabled;
  },

  currentConfig(state) {
    return state;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

function deepMergeTranslationObjects(current, update) {
  Object.keys(update).forEach(key => {
    // if update[key] exist, and it's not a string or array,
    // we go in one level deeper
    if (
      current[key] !== null &&
      // eslint-disable-next-line no-prototype-builtins
      current.hasOwnProperty(key) &&
      typeof current[key] === "object" &&
      !(current[key] instanceof Array)
    ) {
      deepMergeTranslationObjects(current[key], update[key]);
      // if update[key] doesn't exist in current, or it's a string
      // or array, then assign/overwrite current[key] to update[key]
    } else {
      current[key] = update[key];
    }
  });
  return current;
}

function swapItems(arr, a, b) {
  arr[a] = arr.splice(b, 1, arr[a])[0];
  arr[a][LpConfigConstants.COMMON_MODULE_FIELDS.ORDER] = a;
  arr[b][LpConfigConstants.COMMON_MODULE_FIELDS.ORDER] = b;
  return arr;
}

function fixModulesOrder(arr) {
  arr.sort((a, b) => a.order - b.order);
  arr.forEach((module, index) => (module.order = index));
  return arr;
}

function addTempIds(arr) {
  arr.forEach(module => (module.id = module.order));
  return arr;
}
