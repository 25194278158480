function installInterceptors(axios, store) {
  const interceptRequest = createRequestInterceptor(store);

  // eslint-disable-next-line prettier/prettier
  axios.interceptors.request.use(interceptRequest);
}

function createRequestInterceptor(store) {
  const appId = () => store.state.appId;

  return async function (req) {
    const id = appId();
    if (id) {
      req.headers["AppId"] = id;
    }
    return req;
  };
}

export function install(axios) {
  return function (store) {
    installInterceptors(axios, store);
  };
}

export default {
  install,
};
