import axios from "@/web/services/axios";
import UserEventTicketModel from "@/web/store/models/UserEventTicketModel";
import UserSessionTicketModel from "@/web/store/models/UserSessionTicketModel";
import { Constants } from "@/web/constants";

export const types = {
  LOAD_TICKETS: "loadTickets",
  LOAD_TICKETS_SUCCESS: "loadTicketsSuccess",
  LOAD_TICKETS_ERROR: "loadTicketsError",
};

export const state = () => ({
  status: null,
  error: null,
});

export const mutations = {
  [types.LOAD_TICKETS](state) {
    state.status = Constants.STATUS_LOADING;
  },
  [types.LOAD_TICKETS_SUCCESS](state) {
    state.status = Constants.STATUS_LOADED;
  },
  [types.LOAD_TICKETS_ERROR](state, error) {
    state.status = Constants.STATUS_ERROR;
    state.error = error;
  },
};

export const actions = {
  async clearTickets() {
    await UserEventTicketModel.deleteAll();
  },

  async loadTickets({ rootState, getters, commit }) {
    if (!getters["isLoading"]) {
      commit(types.LOAD_TICKETS);
      const eventId = rootState.eventId;
      await UserEventTicketModel.loadUserTickets(eventId)
        .then(result => {
          commit(types.LOAD_TICKETS_SUCCESS);
        })
        .catch(err => {
          commit(types.LOAD_TICKETS_ERROR, err);
        });
    }
  },

  async downloadTicket({ getters }) {
    const ticket = getters.ticket;
    const event = ticket.event_display_data;
    const eventId = event.id;
    const reservationId = ticket.ticket_reservation_uuid;
    const res = await axios.get(`events/${eventId}/ticket/get/${reservationId}`, {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    });
    downloadTicketFile(res.data, `Ticket-${event.title}.pdf`);
  },

  async downloadInvoice({ getters }) {
    const ticket = getters.ticket;
    const event = ticket.event_display_data;
    const eventId = event.id;
    const reservationId = ticket.ticket_reservation_uuid;

    window.location.href = `${process.env.VUE_APP_API_HOST_URL}/events/${eventId}/invoice/get/${reservationId}`;
  },
};

// Workaround for files downloading
function downloadTicketFile(fileBlob, filename) {
  const url = window.URL.createObjectURL(new Blob([fileBlob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
}

export const getters = {
  ticketById: () => ticketId => {
    return UserEventTicketModel.query().whereId(Number(ticketId)).withAll().first();
  },

  ticket() {
    return UserEventTicketModel.query().withAll().first();
  },

  agendaSessionTickets() {
    return UserSessionTicketModel.query().withAll().all();
  },

  isLoading(state) {
    return state.status === Constants.STATUS_LOADING;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
