export function install(Vue) {
  Vue.prototype.$mergeCustomTranslations = function (translations, namespace) {
    for (const locale in translations) {
      const messages = translations[locale];
      this.$i18n.mergeLocaleMessage(locale, { [namespace]: messages });
    }
  };

  Vue.prototype.$setCustomTranslations = function (translations, namespace) {
    for (const locale in translations) {
      const messages = translations[locale];
      const currentMessages = this.$i18n.getLocaleMessage(locale);
      this.$i18n.setLocaleMessage(locale, { ...currentMessages, [namespace]: messages });
    }
  };
}

export default {
  install,
};
