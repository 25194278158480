import { Model } from "@vuex-orm/core";
import AgendaSessionPathModel from "@/web/store/models/AgendaSessionPathModel";
import BusinessMatchingSessionModel from "@/web/store/models/BusinessMatchingSessionModel";
import UserModel from "@/web/store/models/UserModel";
import BusinessMatchingFilterTag from "@/web/store/models/BusinessMatchingFilterTag";

export default class BusinessMatchingFilterGroup extends Model {
  static entity = "bm_filter_groups";

  static fields() {
    return {
      id: this.attr(null),
      event_id: this.number(-1),
      name: this.string(""),
      question: this.string(""),
      order: this.number(-1),
      multi_choice: this.boolean(true),
      required: this.boolean(true),
      question_visible: this.boolean(true),
      business_matching_filter_tag_ids: this.attr([]),
      business_matching_filter_tags: this.hasManyBy(BusinessMatchingFilterTag, "business_matching_filter_tag_ids"),
    };
  }
}
