import EventTicketModel from "@/web/store/models/EventTicketModel";
import { Constants } from "@/web/constants";
import LpConfigConstants from "@/shared/constants/lp-config-constants";

export const state = () => ({
  status: null,
  error: null,
});

export const types = {
  LOAD_EVENT_TICKETS: "loadEventTickets",
  LOAD_SUCCESS: "loadEventTicketsSuccess",
  LOAD_ERROR: "loadEventTicketsError",
};
export const mutations = {
  [types.LOAD_EVENT_TICKETS](state) {
    state.status = Constants.STATUS_LOADING;
  },
  [types.LOAD_SUCCESS](state) {
    state.status = Constants.STATUS_LOADED;
  },
  [types.LOAD_ERROR](state, error) {
    state.status = Constants.STATUS_ERROR;
    state.error = error;
  },
};
export const actions = {
  async fetch({ commit }, { eventId }) {
    commit(types.LOAD_EVENT_TICKETS);

    try {
      await EventTicketModel.api().get(`events/${eventId}/tickets`);
      commit(types.LOAD_SUCCESS);
    } catch (err) {
      commit(types.LOAD_ERROR);
      throw err;
    }
  },
};
export const getters = {
  getById: () => id => {
    return EventTicketModel.query().withAllRecursive().find(id);
  },
  getAllTickets() {
    return EventTicketModel.query().withAllRecursive().all();
  },
  getAllTicketsFromEvent(state, getters, rootState, rootGetters) {
    let eventTicketModules = rootGetters.eventTicketModules;
    if (eventTicketModules.find(module => module.ticket_ids.length === 0) !== undefined) {
      return EventTicketModel.query().withAllRecursive().all();
    }
    let allVisibleEventTicketIds = eventTicketModules.reduce(
      (acc, module) => [...acc, ...module[LpConfigConstants.EVENT_TICKETS_MODULE_FIELDS.TICKET_IDS]],
      []
    );
    return EventTicketModel.query()
      .withAllRecursive()
      .all()
      .filter(ticket => allVisibleEventTicketIds.includes(ticket.id));
  },
  getAllTicketsFromModule: (state, getters, rootState, rootGetters) => moduleOrder => {
    let eventTicketModules = rootGetters.eventTicketModules;
    let foundEventTicketModule = eventTicketModules.find(
      module => module.order === moduleOrder && module.ticket_ids && module.ticket_ids.length
    );
    if (foundEventTicketModule) {
      return EventTicketModel.query()
        .withAllRecursive()
        .all()
        .filter(ticket => foundEventTicketModule.ticket_ids.includes(ticket.id));
    } else {
      return [];
    }
  },
  isLoaded(state) {
    return state.status === Constants.STATUS_LOADED;
  },
  areAllFree(state, getters) {
    return !getters.getAllTicketsFromEvent.find(ticket => ticket.price !== 0);
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
