import { Model } from "@vuex-orm/core";
import AttachmentModel from "@/web/store/models/AttachmentModel";
import AgendaSessionModel from "@/web/store/models/AgendaSessionModel";
import AgendaSessionSpeaker from "@/web/store/models/AgendaSessionSpeaker";

export default class SpeakerModel extends Model {
  static entity = "speakers";

  static fields() {
    return {
      id: this.attr(null),
      event_component_id: this.number(-1),
      order: this.number(-1),
      uuid: this.string(""),
      first_name: this.string(""),
      last_name: this.string(""),
      vip: this.boolean(false),
      position: this.string(null).nullable(),
      company: this.string(null).nullable(),
      description: this.string(null).nullable(),
      country: this.string(null).nullable(),
      show_public_data: this.boolean(false),
      phone: this.string(null).nullable(),
      email: this.string(null).nullable(),
      facebook_profile: this.string(null).nullable(),
      twitter_profile: this.string(null).nullable(),
      linked_profile: this.string(null).nullable(),
      youtube_profile: this.string(null).nullable(),
      instagram_profile: this.string(null).nullable(),
      picture_id: this.attr(null),
      picture: this.belongsTo(AttachmentModel, "picture_id"),
      agenda_sessions: this.belongsToMany(AgendaSessionModel, AgendaSessionSpeaker, "speaker_id", "agenda_session_id"),
    };
  }
}
