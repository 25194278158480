<template>
  <div class="modal-card">
    <header class="modal-card-head has-text-centered">
      <img :src="imageUrl" v-if="imageUrl" class="info-modal-image" />

      <h1 class="modal-card-title">
        {{ title }}
      </h1>
      <div class="close-button" @click="$parent.close()">
        <close-icon class="close-icon"></close-icon>
      </div>
    </header>
    <section class="modal-card-body pt-1 modal-card-foot">
      <div class="size-6 subtitle" :class="textClasses">
        {{ message }}
      </div>
      <div>
        <button class="button is-fullwidth is-primary" style="margin-top: 1rem" @click="$parent.close()">
          {{ $t("common.ok") }}
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import CloseIcon from "@/assets/icon_close.svg";
import LpConfigConstants from "@/shared/constants/lp-config-constants";

export default {
  name: "InfoModal",

  props: {
    title: {
      type: String,
    },

    message: {
      type: String,
    },

    imageUrl: {
      type: String,
    },

    messageAlignment: {
      type: String,
      default: "center",
    },
  },

  components: { CloseIcon },

  computed: {
    textClasses() {
      return {
        "has-text-centered": this.messageAlignment === LpConfigConstants.POPUP_TEXT_ALIGNMENT_OPTIONS.CENTER,
        "has-text-right": this.messageAlignment === LpConfigConstants.POPUP_TEXT_ALIGNMENT_OPTIONS.RIGHT,
        "has-text-left": this.messageAlignment === LpConfigConstants.POPUP_TEXT_ALIGNMENT_OPTIONS.LEFT,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  padding-left: 60px;
  padding-right: 60px;
}

.close-button {
  width: 26px;
  height: 26px;
  cursor: pointer;
  border-radius: 50%;
  background: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  position: absolute;
  right: 30px;

  .close-icon {
    fill: #333333;
    width: 100%;
    height: 100%;
  }
}

.info-modal-image {
  width: 200px;
  margin: 0;
}

.subtitle {
  white-space: pre-line;
}
</style>
